import { Lang, LangObj } from './common.interfaces';

export const LANG: Record<Lang, Lang> = {
  en: 'en',
  vi: 'vi',
};

export const langs: Record<Lang, LangObj> = {
  en: {
    label: 'English',
    value: 'en',
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  vi: {
    label: 'Vietnamese',
    value: 'vi',
    icon: '/assets/icons/flags/ic_flag_vn.svg',
  },
};
export const DATE_FORMAT = 'dd.MM.yyyy';

export enum BooleanEnum {
  TRUE = 1,
  FALSE = -1,
}

export const FIELD = {
  WOOD: 'wood',
};

export const BREADCUMBS = {
  DASHBOARD: 'Trang chủ',
  LIST_EVENT: 'Sự kiện Promotion quý 4',
  CREATE_EVENT: 'Tạo mới sự kiện',
  VIEW_EVENT: 'Xem sự kiện',
  LIST_REGISTER_EVENT: 'List register event',
  LIST_POLICY_CATEGORY: 'List category policy',
  ADD_POLICY_CATEGORY: 'Add category',
  LIST_ENTERPRISE_CATEGORY: 'List category enterprise',
  CATEGORY_ENTERPRISE_LIST: 'List category enterprise ',
  CATEGORY_ENTERPRISE_EDIT: 'Category enterprise edit',
  CATEGORY_ENTERPRISE_NEW: 'category enterprise add new',
  SHOP_INVITATION: 'shop invitation',
  SHOP_INVITATION_lIST: 'shop invitation list',
  ADMIN_LIST: 'List admin',
  STORE_ADMIN: 'Cửa hàng định danh',

  EVENT_PROMOTION_Q4: 'Sự kiện Promotion Quý 4',

  NEW_ADMIN: 'create admin',
  EDIT_EVENT_PRIZE: 'Chỉnh sửa quà tặng sự kiện',
  LIST_EVENT_PRIZE: 'Danh sách quà tặng sự kiện',

  MANAGE_EVENT: 'Quản lý sự kiện',
  MANAGE_LIST_EVENT: 'Danh sách sự kiện',
  MANAGE_CREATE_EVENT: 'Tạo mới sự kiện',
  MANAGE_EDIT_EVENT: 'Chỉnh sửa sự kiện',
  MANAGE_VIEW_EVENT: 'Thông tin sự kiện',

  EVENT_Q1: 'Sự kiện quý 1',
  EVENT_PRIZE: 'Giải thưởng sự kiện',
  EVENT_PRIZE_LIST: 'Danh sách giải',
  EVENT_PRIZE_CREATE: 'Tạo mới giải',
  EVENT_PRIZE_EDIT: 'Chỉnh sửa giải',
  EVENT_PRIZE_DETAIL: 'Thông tin giải',

  MANAGE_GROUP_EVENT: 'Quản lý Group Event',
  LIST_GROUP_EVENT: 'Danh sách Group Event',
  CREATE_GROUP_EVENT: 'Tạo Group Event',
  EDIT_GROUP_EVENT: 'Chỉnh sửa Group Event',
  VIEW_GROUP_EVENT: 'Chi tiết Group Event',

  EVENT_HISTORY_PRIZE: 'Lịch sử trúng giải',
  EVENT_PROMOTION_Q1: 'Sự kiện Promotion Quý 1',

  EVENT_ADD_CAN: 'Sự kiện tích lon',
  EVENT_ADD_CAN_LIST: 'Danh sách Sự kiện tích lon',
  EVENT_ADD_CAN_CREATE: 'Tạo Sự kiện tích lon',
  EVENT_ADD_CAN_EDIT: 'Chỉnh sửa Sự kiện tích lon',
  EVENT_ADD_CAN_DETAIL: 'Chi tiết Sự kiện tích lon',

  CAMPAIGN_MANAGE: 'Quản lý chiến dịch',
  CAMPAIGN_LIST: 'Danh sách chiến dịch',
  CAMPAIGN_CREATE: 'Tạo mới chiến dịch',
  CAMPAIGN_DETAIL: 'Chi tiết chiến dịch',
  CAMPAIGN_EDIT: 'Chỉnh sửa chiến dịch',
  LIST_GROUP_USER: 'Danh sách nhóm người dùng',
  FLOATING_BUTTON_MANAGE: 'Quản lý floating',

  BANNER_MANAGE: 'Quản lý banner',
  BANNER_LIST: 'Danh sách banner',
  BANNER_CREATE: 'Tạo mới banner',
  BANNER_EDIT: 'Chỉnh sửa banner',

  GIFT_MANAGE: 'Quản lý quà',
  GIFT_LIST: 'Danh sách quà',
  GIFT_ORDER_LIST: 'Danh sách quà đặt trước',
  GIFT_CREATE: 'Tạo quà',
  GIFT_EDIT: 'Chỉnh sửa quà',

  USER_GIFT_MANAGE: 'Quản lý User Gift',
  USER_GIFT_LIST: 'Danh sách User Gift',

  ORDER_MANAGE: 'Quản lý đơn hàng',
  ORDER_LIST: 'Danh sách đơn hàng',
  ORDER_DETAIL: 'Chi tiết quà',
  ORDER_SHOW_SHIP: 'Chỉnh sửa ẩn/hiện phí ship',

  COIN_MANAGE: 'Quản lý hoàn xu',
  COIN_LIST: 'Danh sách hoàn xu',
  COIN_DETAIL: 'Chi tiết hoàn xu',
  COIN_EDIT: 'Chỉnh sửa hoàn xu',

  GIFT_CATEGORY: 'Danh sách danh mục',
  GIFT_CATEGORY_CREATE: 'Tạo danh mục',
  GIFT_CATEGORY_EDIT: 'Chỉnh sửa danh mục',

  FILTER_MANAGE: 'Quản lý bộ lọc',
  FILTER_CREATE: 'Tạo mới bộ lọc',
  FILTER_DETAIL: 'Chi tiết bộ lọc',
  FILTER_EDIT: 'Chỉnh sửa bộ lọc',

  HIDDEN_TAG_MANAGE: 'Quản lý Hidden user tag',
  HIDDEN_TAG_CREATE: 'Tạo Hidden User Tag',
  HIDDEN_TAG_EDIT: 'Chỉnh sửa Hidden User Tag',

  GIFT_PRE_ORDER: 'Danh sách quà đặt trước',
};

export const CACHE_TIME = 2 * 1000 * 60;

export const FORMAT_DATE_FILTER = 'MM-DD-YYYY HH:mm:ss';

export const DATE_FORMAT_VN = 'DD-MM-YYYY HH:mm:ss';

export const FORMAT_DATE_NEWS = 'dd-MM-yyyy HH:mm:ss';
export const FORMAT_DATE = 'dd-MM-yyyy HH:mm:ss';

export const LIST_ROUTE = [
  { route: '/my-reward?tab=gift', name: 'Quà tặng của tôi' },
  { route: '/my-reward?tab=e-voucher', name: 'E-voucher của tôi' },
  { route: '/mini-game', name: 'Giải trí' },
  { route: '/notifications', name: 'Danh mục thông báo' },
  { route: '/rewards', name: 'Danh sách quà tặng' },
  { route: '/history?tab=add-point', name: 'Lịch sử tích điểm' },
  { route: '/my-point', name: 'Điểm của tôi' },
  { route: '/home', name: 'Trang chủ' },
  { route: '/change-password', name: 'Đổi mật khẩu' },
  { route: '/add-address', name: 'Thêm địa chỉ' },
  { route: '/chat-bot', name: 'Chat bot' },
  { route: '/delivery-list', name: 'Đơn quà của tôi' },
  { route: '/share', name: 'Chia sẻ bạn bè' },
  { route: '/change-info', name: 'Chỉnh sửa hồ sơ' },
  { route: '/contact', name: 'Liên hệ' },
];

export const IMPORT_OPTION = {
  UPDATE_BILL: 'Cập nhật MVĐ',
  UPDATE_ORDER: 'Đơn giao lại toàn phần',
  UPDATE_ORDER_PART: 'Đơn giao lại 1 phần',
  UPDATE_SL: 'Cập nhật SL qua G1P',
  UPDATE_DELIVERY_PROVIDER: 'Cập nhật TT ĐVVD',
  IMPORT_COIN_LIST: 'Nhập danh sách hoàn xu',
};

export const validTypeImage = ['jpeg', 'jpg', 'png'];
export const helperText = {
  required: 'Trường này không được để trống',
  min: 'Trường này không được nhỏ hơn 0',
  min1: 'Trường này không được nhỏ hơn 1',
};

export const UNIVERSAL_LINK = 'https://vitadairy.page.link/main';
